import { useIntl } from 'react-intl'

import { FormattedMessage } from '@b-stock/bstock-react'
import { ShippingAllowedShipmentTypesEnum } from '@b-stock/listing-api-client'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter, FilterProps } from '../../types'

// The filters endpoint currently does not return allowed shipment types, so we
// must hardcode the values for now.
const AllowedShipmentTypes = {
  [ShippingAllowedShipmentTypesEnum.Binding]: 1,
  [ShippingAllowedShipmentTypesEnum.FlatRate]: 1,
  [ShippingAllowedShipmentTypesEnum.Free]: 1,
  [ShippingAllowedShipmentTypesEnum.BuyerArranged]: 1,
  [ShippingAllowedShipmentTypesEnum.BuyerPickup]: 1,
  [ShippingAllowedShipmentTypesEnum.Other]: 1,
}

// TODO: migrate this enum to @b-stock/bstock-react/locale
const toDisplayName = (option: string) => {
  return (
    <FormattedMessage
      id={`Listings.Enums.shipping.shipmentTypes.${option}`}
      defaultMessage={option}
    />
  )
}

const AllowedShipmentTypesFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}: FilterProps<Set<string>>) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.allowedShipmentTypes',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

AllowedShipmentTypesFilter.getOwnProps = ({
  state,
  data: _data,
  updateFilters,
}) => {
  return {
    items: AllowedShipmentTypes, // data?.allFilters['Allowed Shipment Types'] || {},
    availableItems: AllowedShipmentTypes, // data?.availableFilters['Allowed Shipment Types'] || {},
    value: new Set(state.filters.allowedShipmentTypes),
    onChange: (value: Set<string>) =>
      updateFilters({ allowedShipmentTypes: [...value] }),
  }
}

AllowedShipmentTypesFilter.label = 'Filters.allowedShipmentTypes'

AllowedShipmentTypesFilter.Applied = createCategoricalAppliedComponent(
  'allowedShipmentTypes',
  AllowedShipmentTypesFilter.label,
  toDisplayName
)

export default AllowedShipmentTypesFilter
