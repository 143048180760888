import { useIntl } from 'react-intl'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter, FilterProps } from '../../types'

const CategoryFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}: FilterProps<Set<string>>) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData)
  const availableItems = useFilterOptions(availableItemsData)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      name={intl.formatMessage({
        id: 'Filters.category',
      })}
      selected={value}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

CategoryFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters.Category || {},
    availableItems: data?.availableFilters.Category || {},
    value: new Set(state.filters.category),
    onChange: (value: Set<string>) => updateFilters({ category: [...value] }),
  }
}

CategoryFilter.label = 'Filters.category'

CategoryFilter.Applied = createCategoricalAppliedComponent(
  'category',
  CategoryFilter.label,
  (category: string) => <span>{category}</span>
)

export default CategoryFilter
