import FilterCheckbox from '@components/SearchFilters/shared/FilterCheckbox'

import type { AuctionFilter, FilterProps } from '../../types'

type ValueType = {
  bookmarked: boolean
  noApplication: boolean
  enterprise: boolean
  newSellers: boolean
}

const SellerOtherFilter: AuctionFilter<ValueType> = ({
  onChange,
  value,
}: FilterProps<ValueType>) => {
  return (
    <ul>
      <li>
        <FilterCheckbox
          checked={value.bookmarked}
          onChange={() => onChange({ ...value, bookmarked: !value.bookmarked })}
          label="Filters.other.bookmarked"
        />
      </li>
      <li>
        <FilterCheckbox
          checked={value.noApplication}
          onChange={() =>
            onChange({ ...value, noApplication: !value.noApplication })
          }
          label="Filters.other.noApplication"
        />
      </li>
      <li>
        <FilterCheckbox
          checked={value.enterprise}
          onChange={() => onChange({ ...value, enterprise: !value.enterprise })}
          label="Filters.other.enterprise"
        />
      </li>
      <li>
        <FilterCheckbox
          checked={value.newSellers}
          onChange={() => onChange({ ...value, newSellers: !value.newSellers })}
          label="Filters.other.newSellers"
        />
      </li>
    </ul>
  )
}

SellerOtherFilter.getOwnProps = () => {
  return {
    value: {
      bookmarked: false,
      noApplication: false,
      enterprise: false,
      newSellers: false,
    },
    onChange: (value: ValueType) => {
      console.log('Not implemented.', value)
    },
  }
}

SellerOtherFilter.label = 'Filters.other'

export default SellerOtherFilter
