import { useEffect, useState } from 'react'

import Pusher from 'pusher-js'

import getPusherCredentials from './getPusherCredentials'

const usePusher = () => {
  const [pusher, setPusher] = useState<Pusher>()

  useEffect(() => {
    // This might look strange, why use state and not just return the pusher
    // instance if we can initi it syncrynously? Well, if we want to do
    // teardown in useEffect, we also need to init it here because React
    // will play funny games with us to make sure useEffect setup and teardown
    // are symmetric. There's probably a good reason for this, maybe you know
    // better, but just be forewarned if you try and change this to construct
    // pusher in a useMemo or something, there are weird lifecycle things
    // that you're going to have to figure out, and I burned some time trying
    // to wrestle with that and ultimately ended up here.
    const pusher = new Pusher(...getPusherCredentials())
    setPusher(pusher)

    return () => {
      pusher.connection.unbind_all()
      pusher.disconnect()
    }
  }, [])

  return pusher
}

export default usePusher
